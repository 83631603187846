import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c(
        VFlex,
        [
          _c(
            VRow,
            { staticClass: "ma-3 px-3 pt-3 pb-0" },
            [
              _c(
                VCol,
                {
                  staticClass: "mx-auto mt-5",
                  attrs: { cols: "12", xs: "6", lg: "12" }
                },
                [
                  _c(
                    VCard,
                    {
                      attrs: {
                        color: _vm.colorPainel,
                        shaped: "",
                        outlined: "",
                        block: "",
                        elevation: "1",
                        "max-width": "calc(100%)"
                      }
                    },
                    [
                      _c(
                        VCardTitle,
                        {
                          staticClass: "title font-weight-normal ml-5",
                          style: { color: _vm.dashboardColorFontTitle }
                        },
                        [
                          _c(
                            VIcon,
                            { staticClass: "mr-3", attrs: { color: "white" } },
                            [_vm._v(" mdi-dots-grid ")]
                          ),
                          _vm._v(
                            " Olá " +
                              _vm._s(_vm.computedGetUserName) +
                              ". Bem vindo ao Painel Recuperi "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VRow,
            { staticClass: "mx-6 mt-0 mb-4" },
            [
              _c(
                VCol,
                {
                  staticClass: "mt-6 pt-0 px-4 pb-4",
                  attrs: { cols: "12", xs: "12", lg: "6" }
                },
                [
                  _c(
                    VCard,
                    {
                      staticStyle: {
                        height: "100%",
                        display: "grid",
                        "grid-template-rows": "repeat(2, 1fr)"
                      }
                    },
                    [
                      _c(
                        VSheet,
                        {
                          staticClass: "v-sheet--offset mx-auto",
                          attrs: {
                            color: _vm.colorPainel,
                            elevation: "1",
                            width: "calc(100% - 32px)"
                          }
                        },
                        [
                          _c("Loading", {
                            attrs: {
                              active: _vm.carregandoTotalUploads,
                              "is-full-page": false
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "ma-3 pa-3" },
                            [
                              _c("chartTotalUploads", {
                                staticClass: "mt-5",
                                attrs: {
                                  height: 150,
                                  color: "red",
                                  "chart-data": _vm.dataTotalUpload
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "px-4",
                          staticStyle: {
                            display: "grid",
                            "grid-template-rows": "0.7fr 0.6fr 0.5fr"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "title font-weight-bold mb-2 d-flex align-center",
                              style: { color: _vm.titleColorFont }
                            },
                            [
                              _vm._v(
                                " Upload de Títulos Efetivados no Sistema "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "font-weight-regular grey--text caption"
                            },
                            [
                              _vm._v(
                                " Dados pertencentes a somatória de todos os títulos com upload efetivado no sistema dentro dos últimos 12 meses "
                              ),
                              _c("b", [
                                _vm._v("(títulos irregulares não inclusos)")
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            [
                              _c(VDivider, { staticClass: "my-2" }),
                              _c(
                                VIcon,
                                { staticClass: "mr-2", attrs: { small: "" } },
                                [_vm._v("mdi-clock")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "caption grey--text font-weight-light"
                                },
                                [_vm._v("Atualizado desde o último acesso")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "mt-6 pt-0 px-4 pb-4",
                  attrs: { cols: "12", xs: "12", lg: "6" }
                },
                [
                  _c(
                    VCard,
                    {
                      staticStyle: {
                        height: "100%",
                        display: "grid",
                        "grid-template-rows": "repeat(2, 1fr)"
                      }
                    },
                    [
                      _c(
                        VSheet,
                        {
                          staticClass: "v-sheet--offset mx-auto",
                          attrs: {
                            color: _vm.colorPainel,
                            elevation: "1",
                            width: "calc(100% - 32px)"
                          }
                        },
                        [
                          _c("Loading", {
                            attrs: {
                              active: _vm.carregandoChartRecuperados,
                              "is-full-page": false
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "ma-3 pa-3" },
                            [
                              _c("chartRecuperados", {
                                staticClass: "mt-5",
                                attrs: {
                                  height: 150,
                                  color: "red",
                                  "chart-data": _vm.dataRecuperados
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "px-4",
                          staticStyle: {
                            display: "grid",
                            "grid-template-rows": "0.7fr 0.6fr 0.5fr"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "title font-weight-bold mb-2 d-flex align-center",
                              style: { color: _vm.titleColorFont }
                            },
                            [
                              _vm._v(
                                " Títulos com Desistências, Cancelamentos, Anuências e Pagos em Cartório "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "font-weight-regular grey--text caption"
                            },
                            [
                              _vm._v(
                                " Dados dos últimos 12 meses de títulos com os seguintes comandos: 231 (Enviado à desistência), 241 (Enviado à Cancelamento), 251 (Enviado à Anuência), 101 (Pagos) e 107 (Liquidação em condicional) "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            [
                              _c(VDivider, { staticClass: "my-2" }),
                              _c(
                                VIcon,
                                { staticClass: "mr-2", attrs: { small: "" } },
                                [_vm._v(" mdi-clock ")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "caption grey--text font-weight-light"
                                },
                                [_vm._v("Atualizado desde o último acesso")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VRow,
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                VCol,
                { attrs: { cols: "12", xs: "12", lg: "12" } },
                [
                  _c(
                    VCard,
                    { staticClass: "mt-4 mx-auto" },
                    [
                      _c(
                        VSheet,
                        {
                          staticClass: "v-sheet--offset mx-auto",
                          attrs: {
                            color: _vm.colorPainel,
                            elevation: "1",
                            width: "calc(100% - 32px)"
                          }
                        },
                        [
                          _c(
                            VCardTitle,
                            {
                              staticClass:
                                "white--text title font-weight-bold mb-2"
                            },
                            [_vm._v(" Títulos Existentes ")]
                          )
                        ],
                        1
                      ),
                      _c(VDataTable, {
                        directives: [
                          {
                            def: Intersect,
                            name: "intersect",
                            rawName: "v-intersect",
                            value: _vm.onIntersect,
                            expression: "onIntersect"
                          }
                        ],
                        staticClass: "elevation-1",
                        attrs: {
                          headers: _vm.headers,
                          items: _vm.titulosExistentes,
                          loading: _vm.carregandoDataTable,
                          "hide-default-footer": "",
                          "disable-pagination": ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.ds_status",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _vm._v(" " + _vm._s(item.ds_status) + " "),
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  VIcon,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "my-auto ml-2",
                                                        attrs: { color: "grey" }
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v(" help ")]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getTooltipByStatus(
                                              item.ds_status
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VRow,
            { staticClass: "ma-3 pa-3" },
            [
              _c(
                VCol,
                { attrs: { cols: "12", xs: "12", lg: "12" } },
                [
                  _c(
                    VCard,
                    {
                      staticClass: "mt-4 mx-auto",
                      attrs: { "max-width": "70%" }
                    },
                    [
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { attrs: { sm: "6", lg: "4" } },
                            [
                              _c(
                                VSheet,
                                {
                                  staticClass: "v-sheet--offset ml-3",
                                  attrs: {
                                    color: _vm.colorPainel,
                                    elevation: "12",
                                    "max-width": "fit-content"
                                  }
                                },
                                [
                                  _c(
                                    VIcon,
                                    {
                                      staticClass: "ma-3 pa-3",
                                      attrs: { color: "white", size: "300%" }
                                    },
                                    [_vm._v(" email ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            {
                              staticClass: "d-flex align-center justify-center",
                              attrs: { sm: "6", lg: "8" }
                            },
                            [
                              _c(VCardText, { staticClass: "pt-0" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "font-weight-bold",
                                    staticStyle: { "font-size": "1.7rem" },
                                    style: {
                                      color: _vm.titleColorFont
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " Relatório de E-mails dos últimos 90 dias "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        [
                          _c(
                            VRow,
                            [
                              _c(
                                VCol,
                                {
                                  staticStyle: { display: "grid" },
                                  attrs: { sm: "6", lg: "3" }
                                },
                                [
                                  _c("div", { staticClass: "grey--text" }, [
                                    _vm._v(" E-mails Enviados ")
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-weight-bold display-1 d-flex justify-center align-end",
                                      style: {
                                        color: _vm.titleColorFont
                                      }
                                    },
                                    [
                                      _vm.carregandoRelatorioEmails
                                        ? _c(VProgressCircular, {
                                            attrs: {
                                              color: "primary",
                                              small: "",
                                              indeterminate: ""
                                            }
                                          })
                                        : _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.relatorioEmails
                                                    .emails_enviados
                                                ) +
                                                " "
                                            )
                                          ])
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                VCol,
                                {
                                  staticStyle: { display: "grid" },
                                  attrs: { sm: "6", lg: "3" }
                                },
                                [
                                  _c("div", { staticClass: "grey--text" }, [
                                    _vm._v(" E-mails Recebidos ")
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-weight-bold display-1 d-flex justify-center align-end",
                                      style: {
                                        color: _vm.titleColorFont
                                      }
                                    },
                                    [
                                      _vm.carregandoRelatorioEmails
                                        ? _c(VProgressCircular, {
                                            attrs: {
                                              color: "primary",
                                              small: "",
                                              indeterminate: ""
                                            }
                                          })
                                        : _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.relatorioEmails
                                                    .emails_recebidos
                                                ) +
                                                " "
                                            )
                                          ])
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                VCol,
                                {
                                  staticStyle: { display: "grid" },
                                  attrs: { sm: "6", lg: "3" }
                                },
                                [
                                  _c("div", { staticClass: "grey--text" }, [
                                    _vm._v(" E-mails Abertos ")
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-weight-bold display-1 d-flex justify-center align-end",
                                      style: {
                                        color: _vm.titleColorFont
                                      }
                                    },
                                    [
                                      _vm.carregandoRelatorioEmails
                                        ? _c(VProgressCircular, {
                                            attrs: {
                                              color: "primary",
                                              small: "",
                                              indeterminate: ""
                                            }
                                          })
                                        : _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.relatorioEmails
                                                    .emails_abertos
                                                ) +
                                                " "
                                            )
                                          ])
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                VCol,
                                {
                                  staticStyle: { display: "grid" },
                                  attrs: { sm: "6", lg: "3" }
                                },
                                [
                                  _c("div", { staticClass: "grey--text" }, [
                                    _vm._v(" E-mails com link acessado ")
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-weight-bold display-1 d-flex justify-center align-end",
                                      style: {
                                        color: _vm.titleColorFont
                                      }
                                    },
                                    [
                                      _vm.carregandoRelatorioEmails
                                        ? _c(VProgressCircular, {
                                            attrs: {
                                              color: "primary",
                                              small: "",
                                              indeterminate: ""
                                            }
                                          })
                                        : _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.relatorioEmails
                                                    .emails_com_link_de_contato_acessado
                                                ) +
                                                " "
                                            )
                                          ])
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(VDivider, { staticClass: "my-2" }),
                          _c(
                            VIcon,
                            { staticClass: "mr-2", attrs: { small: "" } },
                            [_vm._v(" mdi-clock ")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "caption grey--text font-weight-light"
                            },
                            [_vm._v("Atualizado desde o último acesso")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.mostrarModalConfirmacao
        ? _c("ConfirmacaoModal", {
            attrs: {
              showDialog: _vm.mostrarModalConfirmacao,
              tituloModal: _vm.tituloModalConfirmacao,
              textoModal: _vm.textoModalConfirmacao,
              btnConfirmarEmit: _vm.btnConfirmarModalConfirmacao,
              btnConfirmar: "Ok",
              esconderCancelar: _vm.esconderCancelar
            },
            on: {
              confirmarAviso: _vm.aceitarModalAviso,
              fecharModal: _vm.aceitarModalAviso
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }