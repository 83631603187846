import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VSnackbar,
    {
      attrs: { timeout: "-1", color: _vm.corSnackbar, top: false },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function(ref) {
            var attrs = ref.attrs
            return [
              _c(
                VBtn,
                _vm._b(
                  { attrs: { text: "" }, on: { click: _vm.fecharSnackbar } },
                  "v-btn",
                  attrs,
                  false
                ),
                [_c(VIcon, { staticClass: "ma-3" }, [_vm._v("mdi-close")])],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.mostrarSnackbar,
        callback: function($$v) {
          _vm.mostrarSnackbar = $$v
        },
        expression: "mostrarSnackbar"
      }
    },
    [_vm._v(_vm._s(_vm.mensagemSnackbar) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }