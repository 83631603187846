import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.isAuthenticated
        ? _c(
            VNavigationDrawer,
            {
              attrs: {
                "hide-overlay": "",
                app: "",
                dark: "",
                src: _vm.backgroundMenu,
                color: _vm.sideBarColor,
                clipped: ""
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "append",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "pa-2" },
                          [
                            _c(
                              VBtn,
                              {
                                attrs: {
                                  block: "",
                                  small: "",
                                  "data-cy": "logout",
                                  loading: _vm.load_logout,
                                  color: _vm.colorBotaoSair
                                },
                                on: { click: _vm.logout }
                              },
                              [
                                _vm._v(" sair "),
                                _c(VIcon, { staticClass: "ml-2" }, [
                                  _vm._v("mdi-logout")
                                ])
                              ],
                              1
                            ),
                            _c(
                              VCardText,
                              { staticClass: "white--text text-center" },
                              [_vm._v("Versão: " + _vm._s(_vm.version))]
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                2948584470
              ),
              model: {
                value: _vm.showDrawer,
                callback: function($$v) {
                  _vm.showDrawer = $$v
                },
                expression: "showDrawer"
              }
            },
            [
              _c(
                VList,
                { attrs: { dense: "", "data-cy": "drawerList" } },
                [
                  _c(
                    VListItem,
                    {
                      attrs: { "two-line": "" },
                      model: {
                        value: _vm.isAuthenticated,
                        callback: function($$v) {
                          _vm.isAuthenticated = $$v
                        },
                        expression: "isAuthenticated"
                      }
                    },
                    [
                      _c(
                        VListItemAvatar,
                        [
                          _c(VIcon, { attrs: { dark: "" } }, [
                            _vm._v("mdi-account-circle")
                          ])
                        ],
                        1
                      ),
                      _c(
                        VListItemContent,
                        [
                          _c(VListItemTitle, { staticClass: "title" }, [
                            _vm._v(_vm._s(_vm.computedGetUserName))
                          ]),
                          _c(VListItemSubtitle, [
                            _vm._v(_vm._s(_vm.computedGetUserLogin))
                          ]),
                          _vm.usuarioAgrupador
                            ? _c(VListItemSubtitle, [
                                _vm._v(
                                  "Agrupador: " + _vm._s(_vm.usuarioAgrupador)
                                )
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "justify-center align-center py-2 px-0",
                              staticStyle: { display: "grid", gap: "8px" }
                            },
                            [
                              _vm.superUsuario
                                ? _c(
                                    VBtn,
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "left"
                                      },
                                      attrs: {
                                        widht: "100%",
                                        "x-small": "",
                                        color: "primary"
                                      }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        {
                                          staticClass: "ma-1 white--text",
                                          attrs: { "x-small": "" }
                                        },
                                        [_vm._v("mdi-checkbox-marked-circle")]
                                      ),
                                      _vm._v(" Super Usuário ")
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                VTooltip,
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _vm.apresentanteNotifica
                                              ? _c(
                                                  VBtn,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "justify-content":
                                                            "center"
                                                        },
                                                        attrs: {
                                                          widht: "100%",
                                                          "x-small": "",
                                                          color: "primary"
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      VIcon,
                                                      {
                                                        staticClass:
                                                          "ma-1 white--text",
                                                        attrs: { "x-small": "" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-checkbox-marked-circle"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" Notifica ")
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    173191758
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          "Apresentante " +
                                            _vm.computedGetApresentante +
                                            " habilitado na Notifica!"
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(VDivider),
                  _vm.usuarioRecuperi
                    ? [
                        _vm._l(_vm.menusRecuperi, function(item) {
                          return [
                            item.childs &&
                            _vm.isShowItem(item, _vm.isAuthenticated)
                              ? _c(
                                  VListGroup,
                                  {
                                    key: item.text,
                                    attrs: {
                                      "data-cy": _vm.getValorDataCypress(item),
                                      color: "white"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function() {
                                            return [
                                              _c(
                                                VListItemIcon,
                                                [
                                                  _c(
                                                    VBtn,
                                                    {
                                                      staticStyle: {
                                                        height: "24px"
                                                      },
                                                      attrs: {
                                                        elevation: "0",
                                                        fab: "",
                                                        text: "",
                                                        small: "",
                                                        loading: _vm.loadingIcon
                                                      }
                                                    },
                                                    [
                                                      _c(VIcon, [
                                                        _vm._v(
                                                          _vm._s(item.icon)
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(VListItemTitle, [
                                                _vm._v(
                                                  " " + _vm._s(item.text) + " "
                                                )
                                              ])
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _vm._l(item.childs, function(child) {
                                      return [
                                        _vm.isShowItem(
                                          child,
                                          _vm.isAuthenticated
                                        )
                                          ? _c(
                                              VListItem,
                                              {
                                                key: child.text,
                                                attrs: {
                                                  to: child.path,
                                                  "data-cy": _vm.getValorDataCypress(
                                                    child
                                                  )
                                                }
                                              },
                                              [
                                                _c(
                                                  VBtn,
                                                  {
                                                    staticStyle: {
                                                      height: "24px"
                                                    },
                                                    attrs: {
                                                      elevation: "0",
                                                      fab: "",
                                                      text: "",
                                                      small: "",
                                                      loading: _vm.loadingIcon
                                                    }
                                                  },
                                                  [
                                                    _c(VIcon, [
                                                      _vm._v(_vm._s(child.icon))
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _c(VListItemTitle, [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(child.text) +
                                                      " "
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    })
                                  ],
                                  2
                                )
                              : _vm.isShowItem(item, _vm.isAuthenticated)
                              ? _c(
                                  VListItem,
                                  {
                                    key: item.text,
                                    attrs: {
                                      to: item.path,
                                      "data-cy": _vm.getValorDataCypress(item)
                                    }
                                  },
                                  [
                                    _c(
                                      VBtn,
                                      {
                                        attrs: {
                                          elevation: "0",
                                          fab: "",
                                          text: "",
                                          small: "",
                                          loading: _vm.loadingIcon
                                        }
                                      },
                                      [
                                        _c(VIcon, [
                                          _vm._v(_vm._s(item.icon))
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(VListItemTitle, [
                                      _vm._v(_vm._s(item.text))
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        })
                      ]
                    : _vm._e(),
                  _vm.usuarioPartner
                    ? [
                        _vm._l(_vm.menusPartner, function(item) {
                          return [
                            item.childs &&
                            _vm.isShowItem(item, _vm.isAuthenticated)
                              ? _c(
                                  VListGroup,
                                  {
                                    key: item.text,
                                    attrs: {
                                      "data-cy": _vm.getValorDataCypress(item),
                                      color: "white"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function() {
                                            return [
                                              _c(
                                                VListItemIcon,
                                                [
                                                  _c(
                                                    VBtn,
                                                    {
                                                      staticStyle: {
                                                        height: "24px"
                                                      },
                                                      attrs: {
                                                        elevation: "0",
                                                        fab: "",
                                                        text: "",
                                                        small: "",
                                                        loading: _vm.loadingIcon
                                                      }
                                                    },
                                                    [
                                                      _c(VIcon, [
                                                        _vm._v(
                                                          _vm._s(item.icon)
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(VListItemTitle, [
                                                _vm._v(
                                                  " " + _vm._s(item.text) + " "
                                                )
                                              ])
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _vm._l(item.childs, function(child) {
                                      return [
                                        _vm.isShowItem(
                                          child,
                                          _vm.isAuthenticated
                                        )
                                          ? _c(
                                              VListItem,
                                              {
                                                key: child.text,
                                                attrs: {
                                                  to: child.path,
                                                  "data-cy": _vm.getValorDataCypress(
                                                    child
                                                  )
                                                }
                                              },
                                              [
                                                _c(
                                                  VBtn,
                                                  {
                                                    staticStyle: {
                                                      height: "24px"
                                                    },
                                                    attrs: {
                                                      elevation: "0",
                                                      fab: "",
                                                      text: "",
                                                      small: "",
                                                      loading: _vm.loadingIcon
                                                    }
                                                  },
                                                  [
                                                    _c(VIcon, [
                                                      _vm._v(_vm._s(child.icon))
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _c(VListItemTitle, [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(child.text) +
                                                      " "
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    })
                                  ],
                                  2
                                )
                              : _vm.isShowItem(item, _vm.isAuthenticated)
                              ? _c(
                                  VListItem,
                                  {
                                    key: item.text,
                                    attrs: {
                                      to: item.path,
                                      "data-cy": _vm.getValorDataCypress(item)
                                    }
                                  },
                                  [
                                    _c(
                                      VBtn,
                                      {
                                        attrs: {
                                          elevation: "0",
                                          fab: "",
                                          text: "",
                                          small: "",
                                          loading: _vm.loadingIcon
                                        }
                                      },
                                      [
                                        _c(VIcon, [
                                          _vm._v(_vm._s(item.icon))
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(VListItemTitle, [
                                      _vm._v(_vm._s(item.text))
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        })
                      ]
                    : _vm._e(),
                  [
                    _c(
                      VListItem,
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.$vuetify.breakpoint.xs,
                            expression: "$vuetify.breakpoint.xs"
                          }
                        ],
                        key: "password",
                        on: { click: _vm.minhaConta }
                      },
                      [
                        _c(
                          VListItemAction,
                          [_c(VIcon, [_vm._v("mdi-account")])],
                          1
                        ),
                        _c(
                          VListItemContent,
                          [_c(VListItemTitle, [_vm._v("Minha Conta")])],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        VAppBar,
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isAuthenticated,
              expression: "isAuthenticated"
            }
          ],
          attrs: {
            "clipped-left": _vm.$vuetify.breakpoint.lgAndUp,
            app: "",
            color: _vm.colorBar,
            dark: "",
            dense: "",
            "data-cy": "homeToolbar"
          }
        },
        [
          _c(
            VToolbarTitle,
            { staticClass: "mt-1" },
            [
              _c(
                VLayout,
                [
                  _c(VAppBarNavIcon, {
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.changeDrawer.apply(null, arguments)
                      }
                    }
                  }),
                  _c(
                    "router-link",
                    { attrs: { to: "" } },
                    [
                      _vm.usuarioRecuperi
                        ? _c(VImg, {
                            staticClass: "mt-1",
                            attrs: {
                              src: _vm.logo,
                              alt: "logo-recuperi",
                              width: "150px"
                            }
                          })
                        : _vm._e(),
                      _vm.usuarioPartner
                        ? _c(VImg, {
                            staticClass: "mt-1",
                            attrs: {
                              src: _vm.logoBvs,
                              alt: "logo-bvs",
                              width: "190px"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.superUsuario && _vm.usuarioRecuperi
                    ? _c(VSwitch, {
                        staticClass: "ml-2 mt-3",
                        attrs: {
                          label: "Beta",
                          color: "red",
                          "data-cy": "switchBeta"
                        },
                        on: {
                          click: function($event) {
                            return _vm.setVersaoBeta()
                          }
                        },
                        model: {
                          value: _vm.versaoBeta,
                          callback: function($$v) {
                            _vm.versaoBeta = $$v
                          },
                          expression: "versaoBeta"
                        }
                      })
                    : _vm._e(),
                  _c(
                    VBtn,
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.avisoHomologacao,
                          expression: "avisoHomologacao"
                        }
                      ],
                      staticClass: "success ml-4 mt-3 font-weight-black",
                      attrs: { small: "" }
                    },
                    [
                      _c(VIcon, { attrs: { "x-small": "" } }, [
                        _vm._v("mdi-information-outline")
                      ]),
                      _vm._v("homologação ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(VSpacer),
          _vm.userProfile && _vm.superUsuario
            ? _c(
                "div",
                {
                  staticClass: "title-apresentante cursor-pointer",
                  attrs: { "data-cy": "alterar-apresentante" },
                  on: { click: _vm.alterarApresentante }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.userProfile.cd_apresentante) +
                      " - " +
                      _vm._s(_vm.userProfile.nm_apresentante) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _vm.userProfile && !_vm.superUsuario
            ? _c("div", { staticClass: "title-apresentante cursor-pointer" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.userProfile.cd_apresentante) +
                    " - " +
                    _vm._s(_vm.userProfile.nm_apresentante) +
                    " "
                )
              ])
            : _vm._e(),
          _c(VSpacer),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.$vuetify.breakpoint.xs,
                  expression: "!$vuetify.breakpoint.xs"
                }
              ]
            },
            [
              _c(
                VMenu,
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            VBtn,
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    color: _vm.colorBotaoSair,
                                    "x-small": "",
                                    elevation: "2",
                                    fab: ""
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c(VIcon, {}, [_vm._v("mdi-bell-badge")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    VList,
                    { attrs: { "max-width": "400px" } },
                    _vm._l(_vm.itemsNotification, function(item, index) {
                      return _c(
                        VListItem,
                        { key: index },
                        [
                          _c(VListItemTitle, { staticClass: "ma-2" }, [
                            _vm._v(_vm._s(item.title))
                          ]),
                          _c(VDialog, {
                            attrs: {
                              transition: "dialog-top-transition",
                              "max-width": "600"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        VBtn,
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                small: "",
                                                elevation: "0"
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-chevron-right")
                                          ]),
                                          _vm._v(" Ver detalhes ")
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "default",
                                  fn: function(dialog) {
                                    return [
                                      _c(
                                        VCard,
                                        [
                                          _c(
                                            VToolbar,
                                            {
                                              attrs: {
                                                color: "primary",
                                                dark: ""
                                              }
                                            },
                                            [
                                              _c(
                                                VCardTitle,
                                                {
                                                  staticClass:
                                                    "font-weight-black"
                                                },
                                                [
                                                  _vm._v(
                                                    "Detalhes da atualização na versão: " +
                                                      _vm._s(item.versao)
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(VCardTitle, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grey--text font-weight-black"
                                              },
                                              [
                                                _vm._v(
                                                  " Título: " +
                                                    _vm._s(item.title) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]),
                                          _c(VCardText, {}, [
                                            _vm._v(
                                              " Descrição: " +
                                                _vm._s(item.descricao) +
                                                " "
                                            )
                                          ]),
                                          _c(
                                            VCardActions,
                                            { staticClass: "justify-end" },
                                            [
                                              _c(
                                                VBtn,
                                                {
                                                  attrs: { text: "" },
                                                  on: {
                                                    click: function($event) {
                                                      dialog.value = false
                                                    }
                                                  }
                                                },
                                                [_vm._v("fechar")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          !_vm.$vuetify.breakpoint.xs && _vm.isSuperUser
            ? _c(
                "div",
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "mx-2",
                      attrs: {
                        color: _vm.colorBotaoSair,
                        small: "",
                        "data-cy": "btnRelatorios"
                      },
                      on: {
                        click: function($event) {
                          _vm.dialogModalRelatorios = true
                        }
                      }
                    },
                    [
                      _vm._v(" Relatórios "),
                      _c(
                        VIcon,
                        { staticClass: "mx-2", attrs: { small: "" } },
                        [_vm._v("mdi-file-chart-outline")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.$vuetify.breakpoint.xs,
                  expression: "!$vuetify.breakpoint.xs"
                }
              ]
            },
            [
              _c(
                VBtn,
                {
                  staticClass: "mx-2",
                  attrs: { color: _vm.colorBotaoSair, small: "" },
                  on: { click: _vm.minhaConta }
                },
                [
                  _vm._v(" Minha Conta "),
                  _c(VIcon, { staticClass: "mx-2", attrs: { small: "" } }, [
                    _vm._v("mdi-account")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {},
            [
              _c(
                VBtn,
                {
                  staticClass: "mx-2",
                  attrs: {
                    loading: _vm.load_logout,
                    small: "",
                    color: _vm.colorBotaoSair,
                    "data-cy": "logout"
                  },
                  on: { click: _vm.logout }
                },
                [
                  _vm._v(" Sair "),
                  _c(VIcon, { staticClass: "mx-2", attrs: { small: "" } }, [
                    _vm._v("mdi-exit-to-app")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm.dialogModalRelatorios
            ? _c("ModalRelatoriosSolicitados", {
                attrs: { dialogModalRelatorios: _vm.dialogModalRelatorios },
                on: {
                  fecharModal: function($event) {
                    _vm.dialogModalRelatorios = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }