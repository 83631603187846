<template>
    <span>
        <v-navigation-drawer
            v-if="isAuthenticated"
            v-model="showDrawer"
            hide-overlay
            app
            dark
            :src="backgroundMenu"
            :color="sideBarColor"
            clipped
        >
            <v-list dense data-cy="drawerList">                
                <v-list-item v-model="isAuthenticated" two-line>
                    <v-list-item-avatar>
                        <v-icon dark>mdi-account-circle</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="title">{{
                            computedGetUserName
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                            computedGetUserLogin
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle v-if="usuarioAgrupador"
                            >Agrupador:
                            {{ usuarioAgrupador }}</v-list-item-subtitle
                        >
                        <div
                            class="justify-center align-center py-2 px-0"
                            style="display: grid; gap: 8px"
                        >
                            <v-btn
                                v-if="superUsuario"
                                widht="100%"
                                x-small
                                color="primary"
                                style="display: flex; justify-content: left;"
                            >
                                <v-icon x-small class="ma-1 white--text"
                                    >mdi-checkbox-marked-circle</v-icon
                                >
                                Super Usuário
                            </v-btn>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        v-if="apresentanteNotifica"
                                        widht="100%"
                                        x-small
                                        color="primary"
                                        style="display: flex; justify-content: center;"
                                    >
                                        <v-icon x-small class="ma-1 white--text"
                                            >mdi-checkbox-marked-circle</v-icon
                                        >
                                        Notifica
                                    </v-btn>
                                </template>
                                <span>
                                    {{
                                        `Apresentante ${computedGetApresentante} habilitado na Notifica!`
                                    }}
                                </span>
                            </v-tooltip>
                        </div>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <!-- Menu para usuário Recuperi -->
                <template v-if="usuarioRecuperi">
                    <template v-for="item in menusRecuperi">
                        <v-list-group
                            v-if="
                                item.childs && isShowItem(item, isAuthenticated)
                            "
                            :key="item.text"
                            :data-cy="getValorDataCypress(item)"
                            color="white"
                        >
                            <template v-slot:activator>
                                <v-list-item-icon>
                                    <v-btn
                                        style="height: 24px"
                                        elevation="0"
                                        fab
                                        text
                                        small
                                        :loading="loadingIcon"
                                    >
                                        <v-icon>{{ item.icon }}</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    {{ item.text }}
                                </v-list-item-title>
                            </template>
                            <template v-for="child in item.childs">
                                <v-list-item
                                    v-if="isShowItem(child, isAuthenticated)"
                                    :key="child.text"
                                    :to="child.path"
                                    :data-cy="getValorDataCypress(child)"
                                >
                                    <v-btn
                                        style="height: 24px"
                                        elevation="0"
                                        fab
                                        text
                                        small
                                        :loading="loadingIcon"
                                    >
                                        <v-icon>{{ child.icon }}</v-icon>
                                    </v-btn>
                                    <v-list-item-title>
                                        {{ child.text }}
                                    </v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-list-group>
                        <v-list-item
                            v-else-if="isShowItem(item, isAuthenticated)"
                            :key="item.text"
                            :to="item.path"
                            :data-cy="getValorDataCypress(item)"
                        >
                            <v-btn
                                elevation="0"
                                fab
                                text
                                small
                                :loading="loadingIcon"
                            >
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-btn>
                            <v-list-item-title>{{
                                item.text
                            }}</v-list-item-title>
                        </v-list-item>
                    </template>
                </template>

                <!-- Menu para usuário Partner -->
                <template v-if="usuarioPartner">
                    <template v-for="item in menusPartner">
                        <v-list-group
                            v-if="
                                item.childs && isShowItem(item, isAuthenticated)
                            "
                            :key="item.text"
                            :data-cy="getValorDataCypress(item)"
                            color="white"
                        >
                            <template v-slot:activator>
                                <v-list-item-icon>
                                    <v-btn
                                        style="height: 24px"
                                        elevation="0"
                                        fab
                                        text
                                        small
                                        :loading="loadingIcon"
                                    >
                                        <v-icon>{{ item.icon }}</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    {{ item.text }}
                                </v-list-item-title>
                            </template>
                            <template v-for="child in item.childs">
                                <v-list-item
                                    v-if="isShowItem(child, isAuthenticated)"
                                    :key="child.text"
                                    :to="child.path"
                                    :data-cy="getValorDataCypress(child)"
                                >
                                    <v-btn
                                        style="height: 24px"
                                        elevation="0"
                                        fab
                                        text
                                        small
                                        :loading="loadingIcon"
                                    >
                                        <v-icon>{{ child.icon }}</v-icon>
                                    </v-btn>
                                    <v-list-item-title>
                                        {{ child.text }}
                                    </v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-list-group>
                        <v-list-item
                            v-else-if="isShowItem(item, isAuthenticated)"
                            :key="item.text"
                            :to="item.path"
                            :data-cy="getValorDataCypress(item)"
                        >
                            <v-btn
                                elevation="0"
                                fab
                                text
                                small
                                :loading="loadingIcon"
                            >
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-btn>
                            <v-list-item-title>{{
                                item.text
                            }}</v-list-item-title>
                        </v-list-item>
                    </template>
                </template>
                <template>
                    <v-list-item
                        key="password"
                        @click="minhaConta"
                        v-show="$vuetify.breakpoint.xs"
                    >
                        <v-list-item-action>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Minha Conta</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
            <template v-slot:append>
                <div class="pa-2">
                    <v-btn
                        block
                        small
                        @click="logout"
                        data-cy="logout"
                        :loading="load_logout"
                        :color="colorBotaoSair"
                    >
                        sair
                        <v-icon class="ml-2">mdi-logout</v-icon>
                    </v-btn>
                    <v-card-text class="white--text text-center"
                        >Versão: {{ version }}</v-card-text
                    >
                </div>
            </template>
        </v-navigation-drawer>
        <v-app-bar
            v-show="isAuthenticated"
            :clipped-left="$vuetify.breakpoint.lgAndUp"
            app
            :color="colorBar"
            dark
            dense
            data-cy="homeToolbar"
        >
            <v-toolbar-title class="mt-1">
                <v-layout>
                    <v-app-bar-nav-icon
                        @click.stop="changeDrawer"
                    ></v-app-bar-nav-icon>

                    <router-link to="">
                        <v-img
                            v-if="usuarioRecuperi"
                            :src="logo"
                            alt="logo-recuperi"
                            width="150px"
                            class="mt-1"
                        />
                        <v-img
                            v-if="usuarioPartner"
                            :src="logoBvs"
                            alt="logo-bvs"
                            width="190px"
                            class="mt-1"
                        />
                    </router-link>
                    <v-switch
                        v-if="superUsuario && usuarioRecuperi"
                        v-model="versaoBeta"
                        class="ml-2 mt-3"
                        label="Beta"
                        color="red"
                        data-cy="switchBeta"
                        @click="setVersaoBeta()"
                    ></v-switch>
                    <v-btn
                        v-show="avisoHomologacao"
                        class="success ml-4 mt-3 font-weight-black"
                        small
                    >
                        <v-icon x-small>mdi-information-outline</v-icon
                        >homologação
                    </v-btn>
                </v-layout>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div 
                v-if="userProfile && superUsuario"
                class="title-apresentante cursor-pointer"
                data-cy="alterar-apresentante"
                @click="alterarApresentante">
                {{userProfile.cd_apresentante}} - {{userProfile.nm_apresentante}}
            </div>
            <div 
                v-if="userProfile && !superUsuario"
                class="title-apresentante cursor-pointer"
                >
                {{userProfile.cd_apresentante}} - {{userProfile.nm_apresentante}}
            </div>
            <v-spacer></v-spacer>
            <div v-show="!$vuetify.breakpoint.xs">
                <v-menu offset-y>
                    <template
                        v-slot:activator="{
                            on,
                            attrs
                        }"
                    >
                        <v-btn
                            :color="colorBotaoSair"
                            x-small
                            elevation="2"
                            class="ma-2"
                            fab
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon class="">mdi-bell-badge</v-icon>
                        </v-btn>
                    </template>
                    <v-list max-width="400px">
                        <v-list-item
                            v-for="(item, index) in itemsNotification"
                            :key="index"
                        >
                            <v-list-item-title class="ma-2">{{
                                item.title
                            }}</v-list-item-title>
                            <v-dialog
                                transition="dialog-top-transition"
                                max-width="600"
                            >
                                <template
                                    v-slot:activator="{
                                        on,
                                        attrs
                                    }"
                                >
                                    <v-btn
                                        small
                                        elevation="0"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>mdi-chevron-right</v-icon>
                                        Ver detalhes
                                    </v-btn>
                                </template>
                                <template v-slot:default="dialog">
                                    <v-card>
                                        <v-toolbar color="primary" dark>
                                            <v-card-title
                                                class="font-weight-black"
                                                >Detalhes da atualização na
                                                versão:
                                                {{ item.versao }}</v-card-title
                                            >
                                        </v-toolbar>
                                        <v-card-title>
                                            <div
                                                class="
                                                                        grey--text
                                                                        font-weight-black
                                                                    "
                                            >
                                                Título:
                                                {{ item.title }}
                                            </div>
                                        </v-card-title>
                                        <v-card-text class="">
                                            Descrição:
                                            {{ item.descricao }}
                                        </v-card-text>
                                        <v-card-actions class="justify-end">
                                            <v-btn
                                                text
                                                @click="dialog.value = false"
                                                >fechar</v-btn
                                            >
                                        </v-card-actions>
                                    </v-card>
                                </template>
                            </v-dialog>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <div v-if="!$vuetify.breakpoint.xs && isSuperUser">
                <v-btn
                    @click="dialogModalRelatorios = true"
                    :color="colorBotaoSair"
                    small
                    class="mx-2"
                    data-cy="btnRelatorios"
                >
                    Relatórios
                    <v-icon small class="mx-2">mdi-file-chart-outline</v-icon>
                </v-btn>
            </div>
            <div v-show="!$vuetify.breakpoint.xs">
                <v-btn
                    @click="minhaConta"
                    :color="colorBotaoSair"
                    small
                    class="mx-2"
                >
                    Minha Conta
                    <v-icon small class="mx-2">mdi-account</v-icon>
                </v-btn>
            </div>
            <div class="">
                <v-btn
                    :loading="load_logout"
                    small
                    :color="colorBotaoSair"
                    @click="logout"
                    class="mx-2"
                    data-cy="logout"
                >
                    Sair
                    <v-icon small class="mx-2">mdi-exit-to-app</v-icon>
                </v-btn>
            </div>
            <ModalRelatoriosSolicitados
                v-if="dialogModalRelatorios"
                :dialogModalRelatorios="dialogModalRelatorios"
                @fecharModal="dialogModalRelatorios = false"
            />
        </v-app-bar>
    </span>
</template>

<script>
import Vue from 'vue';
import ModalRelatoriosSolicitados from '@/modals/ModalRelatoriosSolicitados';
import MenuRecuperi from '@/helpers/MenuRecuperi';
import menuPartner from '@/helpers/MenuPartner';
import atualizacoesRelease from '@/helpers/UpdatesRelease';
import router from '@/router';
import SenhaService from '@/services/redefinirSenhaService';
import versionInfo from '../helpers/VERSION.md';

export default {
    name: 'AppNavigation',
    components: {
        ModalRelatoriosSolicitados
    },
    data: () => ({
        versaoBeta: false,
        version: versionInfo,
        dialogModalRelatorios: false,
        loadingIcon: true,
        avisoHomologacao: false,
        colorBar: 'primary',
        usuarioLite: false,
        usuarioBasic: false,
        usuarioAdmin: false,
        superUsuario: false,
        admins: [
            ['Management', 'mdi-account-multiple-outline'],
            ['Settings', 'mdi-cog-outline']
        ],
        cruds: [
            ['Create', 'mdi-plus-outline'],
            ['Read', 'mdi-file-outline'],
            ['Update', 'mdi-update'],
            ['Delete', 'mdi-delete']
        ],
        drawer: true, //INFO: determina de menu lateral carrega aberto
        itemsNotification: atualizacoesRelease,
        menusRecuperi: MenuRecuperi,
        menusPartner: menuPartner,
        load_logout: false,
        logo: require('@/assets/logo-recuperi-2400.png'),
        logoBvs: require('@/assets/logo-recuperi-boa-vista.png'),
        user_profile: {},
        usuarioPartner: false,
        usuarioRecuperi: false,
        colorBotaoSair: null,
        backgroundMenu: require('@/assets/background-menu-recuperi.jpg'),
        sideBarColor: null
    }),
    computed: {
        userProfile() {
            return this.$store.getters.user;
        },
        isAuthenticated() {
            return this.$store.getters.isAuthenticated;
        },
        computedGetUserName() {
            return this.$store.getters.getUserName;
        },
        computedGetApresentante() {
            return this.$store.getters.cdApresentante;
        },
        computedGetUserEmail() {
            return this.$store.getters.getUserEmail;
        },
        computedGetUserLogin() {
            return this.$store.getters.getUserLogin;
        },
        isSuperUser() {
            if (this.$store.getters.getSuperUser == 1) {
                return true;
            }
            return false;
        },
        computedGetPartner() {
            return this.$store.getters.isPartner;
        },
        situacoesTitulo() {
            return this.$store.getters.situacoesTitulo;
        },
        usuarioAgrupador() {
            return this.$store.getters.agrupadorUsuario;
        },
        apresentanteNotifica() {
            return this.$store.getters.apresentanteNotifica;
        },
        showDrawer: {
            get() {
                if (this.drawer && this.isAuthenticated == true) {
                    return true;
                } else {
                    return false;
                }
            },
            set() {
                this.drawer = this.drawer;
            }
        },
        isUserReadonly() {
            return this.$store.getters.isUserReadonly;
        },
        isApresentanteCraNacional() {
            return this.$store.getters.isApresentanteCraNacional;
        }
    },
    methods: {
        setVersaoBeta() {
            console.log('setVersaoBeta', this.versaoBeta);
            // this.versaoBeta = !this.versaoBeta;
            this.$store.dispatch('setVersaoBeta', this.versaoBeta);
        },
        getValorDataCypress(item) {
            const path = item.path.replaceAll('/', '');
            return `path${path}`;
        },
        logout() {
            this.load_logout = true;
            this.$store.dispatch('userLogout');
            router.push('/login');
            this.load_logout = false;
            //seta o drawer para true apenas para que quando o usuário logue novamente, ele ja venha aberto
            this.drawer = true;
        },
        validarMenuByUsuario(item) {
            let validouMenu = true;
            if (item.hide && item.hide.length > 0) {
                for (let index = 0; index < item.hide.length; index++) {
                    const tipoUsuario = item.hide[index];
                    const tiposUsuario = tipoUsuario.split(' ');

                    if(item.path == '/alteracoes-em-lote' && this.usuarioBasic && !this.isApresentanteCraNacional){
                        return false;
                    }

                    if (tiposUsuario?.length > 1) {
                        if (this.usuarioAdmin && !this.superUsuario) {
                            validouMenu = false;
                            break;
                        }
                    } else {
                        if (this[tipoUsuario]) {
                            validouMenu = false;
                            break;
                        }
                    }
                }
            }
            return validouMenu;
        },
        validarMenuUsuarioAgrupador(menu) {
            if (!this.usuarioAgrupador) {
                return true;
            }

            const proibidosAgrupador = [
                '/upload-titulos',
                '/agrupador-em-massa'
            ];
            if (proibidosAgrupador.includes(menu.path)) {
                return false;
            }

            return true;
        },
        validarMenuPorApresentante(menu) {
            if (
                menu.path === '/upload-em-massa' &&
                !this.isApresentanteCraNacional
            ) {
                return false;
            }

            if (this.computedGetApresentante === 'TST') {
                const proibidosTST = ['/documentacao-api'];
                if (proibidosTST.includes(menu.path)) {
                    return false;
                }
            }

            return true;
        },
        isShowItem(item, authenticated) {
            if (item.authRequired && !authenticated) {
                return false;
            } else {
                if (
                    !this.validarMenuPorApresentante(item) ||
                    !this.validarMenuByUsuario(item) ||
                    !this.validarMenuUsuarioAgrupador(item)
                ) {
                    return false;
                }

                return true;
            }
        },
        returnHome() {
            return router.push('/');
        },
        changeDrawer() {
            this.drawer = !this.drawer;
        },
        minhaConta() {
            return new Promise((resolve, reject) => {
                try {
                    let senhaService = new SenhaService(Vue.http, this.$store);
                    senhaService.redefinirSenha().then(
                        response => {
                            if (response.status == 200) {
                                this.user_profile = response.body;
                                router.addRoute({
                                    path: '/minha-conta',
                                    name: 'MinhaConta',
                                    component: () =>
                                        import('@/views/MinhaConta.vue'),
                                    props: routes => ({
                                        user_profile: this.user_profile,
                                        ...routes.params
                                    })
                                });
                                return router.push({
                                    name: 'MinhaConta'
                                });
                            }
                        },
                        error => {
                            console.log(error);
                        }
                    );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        alterarApresentante() {
            if(this.superUsuario){
                router.push('/trocar-apresentante');
            }
            return;
        },
        constructor() {
            this.versaoBeta = this.$store.getters.isBetaVersion;
        if (document.location.href.indexOf('painel-hom.recuperi') > -1) {
            console.log('Em modo Homologação');
            this.avisoHomologacao = true;
            this.colorBar = 'secondary';
        } else {
            console.log('Modo Homologação não identificado');
        }
        if (this.$store.getters.isLite == 1) {
            this.usuarioLite = true;
            this.usuarioBasic = false;
        } else {
            this.usuarioLite = false;
            this.usuarioBasic = true;
        }
        if (this.$store.getters.isPartner == 1) {
            this.usuarioPartner = true;
            this.usuarioRecuperi = false;
            this.backgroundMenu = null;
            this.$vuetify.theme.themes.light.primary = '#3d58d8';
            this.$vuetify.theme.themes.light.secondary = '#fbc93b';
            this.colorBotaoSair = '#2f429b';
        } else {
            if(this.userProfile && this.userProfile.ds_identidade_visual){
                this.sideBarColor = this.userProfile.ds_identidade_visual.sidebar_color;
                this.backgroundMenu = null
            }
            this.usuarioRecuperi = true;
            this.$vuetify.theme.themes.light.primary = this.userProfile && this.userProfile.ds_identidade_visual ? this.userProfile.ds_identidade_visual.primary : '#26c6da';
            this.$vuetify.theme.themes.light.secondary = this.userProfile && this.userProfile.ds_identidade_visual ?this.userProfile.ds_identidade_visual.secondary : '#00acc1';
            this.colorBotaoSair = this.userProfile && this.userProfile.ds_identidade_visual ? this.userProfile.ds_identidade_visual.secondary : '#26c6da';
            this.logo = this.userProfile && this.userProfile.ds_identidade_visual ? this.userProfile.ds_identidade_visual.url_logo : require('@/assets/logo-recuperi-2400.png');
        }
        if (this.$store.getters.getSuperUser == 1) {
            this.superUsuario = true;
        } else {
            this.superUsuario = false;
        }
        if (this.$store.getters.isAdmin == 1) {
            this.usuarioAdmin = true;
            this.usuarioBasic = false;
        } else {
            this.usuarioAdmin = false;
        }
        }
    },
    created() {
        setTimeout(() => {
            this.loadingIcon = false;
        }, 2000);
    },
    mounted() {
        this.constructor();
    },
    beforeUpdate() {
        this.constructor();
    }

};
</script>
<style scoped>
.title-apresentante{
    font-size: 16px;
    font-weight: bold;
}
.cursor-pointer{
    cursor: pointer;
}
</style>
