import { VApp } from 'vuetify/lib/components/VApp';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VApp,
    { attrs: { id: "inspire" } },
    [
      _vm.$route.path !== "/login" ? _c("app-navigation") : _vm._e(),
      _c(
        VMain,
        { attrs: { id: "principal", height: "100%", width: "100px" } },
        [_c("router-view")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }