import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ma-3" },
    [
      _c(
        VCard,
        { attrs: { align: "left", justify: "left" } },
        [
          _c(
            VCardText,
            { staticClass: "pa-3 font-weight-light white black--text" },
            [
              _c(VIcon, { staticClass: "primary--text pa-2" }, [
                _vm._v(" mdi-domain ")
              ]),
              _vm._v("Inicio > Sobre nós ")
            ],
            1
          )
        ],
        1
      ),
      _c(
        VCard,
        { attrs: { color: _vm.primaryColorFont, height: "100vh" } },
        [
          _c(
            VRow,
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                VCol,
                {
                  staticClass: "text-center ma-3 pa-3",
                  attrs: { md: "12", lg: "10" }
                },
                [
                  _c(
                    VCardText,
                    { staticClass: "display-2 white--text font-weight-black" },
                    [
                      _vm._v(
                        " Somos a Recuperi, sua parceira na recuperação de valores! "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { md: "12", lg: "12" } },
                [
                  _c(
                    VCardText,
                    {
                      staticClass:
                        "display-4 white--text font-weight-black text-center"
                    },
                    [_vm._v("+ de 2.2 bilhões ")]
                  )
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { md: "12", lg: "8" } },
                [
                  _c(
                    VCardText,
                    { staticClass: "white--text font-weight-normal" },
                    [
                      _vm._v(
                        " Nossos clientes já contam com mais de 2.2 bilhões de reais em dívidas protestadas/recuperadas no último ano (entre duplicatas, cédulas bancárias e outros títulos em atraso). "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "mx-auto", attrs: { sm: "12", md: "11" } },
            [
              _c(
                VCard,
                { staticClass: "ma-5 Pa-5" },
                [
                  _c(VImg, {
                    staticClass: "mx-auto my-auto",
                    attrs: {
                      src: require("@/assets/banner-1-site.png"),
                      "full-width": ""
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            VRow,
            [
              _c(
                VCol,
                {
                  staticClass: "text-left ma-auto",
                  attrs: { md: "12", lg: "10", cols: "10" }
                },
                [
                  _c(
                    VCardText,
                    {
                      staticClass: "display-2 primary--text font-weight-black"
                    },
                    [
                      _c(VIcon, { staticClass: "primary--text pa-2" }, [
                        _vm._v(" mdi-domain ")
                      ]),
                      _vm._v("Sobre nós ")
                    ],
                    1
                  ),
                  _c(VCardText, [
                    _vm._v(
                      " A Recuperi é uma startup paulistana fundada em 2017, com atuação em todo o território brasileiro. Desenvolvemos nossas Plataformas com tecnologia própria e um modelo de negócios inovador, para ajudar você desde a emissão de boletos até a cobrança e negociação de valores em atraso. Queremos ser a sua parceira na recuperação de valores, trazendo uma estrutura única, com maior controle gerencial e menos custos. "
                    )
                  ])
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "text-left ma-auto",
                  attrs: { md: "12", lg: "10", cols: "10" }
                },
                [
                  _c(
                    VCardText,
                    {
                      staticClass: "display-2 primary--text font-weight-black"
                    },
                    [
                      _c(VIcon, { staticClass: "primary--text" }, [
                        _vm._v(" mdi-finance ")
                      ]),
                      _vm._v(" Já somos 2.2 bilhões! ")
                    ],
                    1
                  ),
                  _c(VCardText, [
                    _vm._v(
                      " Nossos clientes já contam com mais de 2.2 bilhões de reais em dívidas protestadas/recuperadas no último ano (entre duplicatas, cédulas bancárias e outros títulos em atraso). "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "ma-auto", attrs: { md: "10", lg: "12" } },
            [
              _c(
                VRow,
                [
                  _c(VCol, {
                    staticClass: "text-center",
                    attrs: { md: "12", lg: "1", cols: "12" }
                  }),
                  _c(
                    VCol,
                    {
                      staticClass: "text-center",
                      attrs: { md: "12", lg: "2" }
                    },
                    [
                      _c(
                        VCard,
                        {
                          staticClass: "primary",
                          attrs: { rounded: "", "min-height": "270px" }
                        },
                        [
                          _c(
                            VIcon,
                            {
                              staticClass: "white--text my-5",
                              attrs: { size: "100" }
                            },
                            [_vm._v(" mdi-format-list-checks ")]
                          ),
                          _c(
                            VCardText,
                            {
                              staticClass: "title white--text font-weight-black"
                            },
                            [_vm._v(" Emissão de Protestos ")]
                          )
                        ],
                        1
                      ),
                      _c(VCardText, { staticClass: "text-left" }, [
                        _vm._v(
                          " Protestos automáticos enviados diretamente aos cartórios através de conexão de dados "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "text-center",
                      attrs: { md: "12", lg: "2" }
                    },
                    [
                      _c(
                        VCard,
                        {
                          staticClass: "primary",
                          attrs: { rounded: "", "min-height": "270px" }
                        },
                        [
                          _c(
                            VIcon,
                            {
                              staticClass: "white--text my-5",
                              attrs: { size: "100" }
                            },
                            [_vm._v(" mdi-cash-check ")]
                          ),
                          _c(
                            VCardText,
                            {
                              staticClass: "title white--text font-weight-black"
                            },
                            [_vm._v(" Recuperação de títulos ")]
                          )
                        ],
                        1
                      ),
                      _c(VCardText, { staticClass: "text-left" }, [
                        _vm._v(
                          " Fazemos a recuperação após o upload dos títulos no sistema com ótima taxa de retorno e recuperação. "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "text-center",
                      attrs: { md: "12", lg: "2" }
                    },
                    [
                      _c(
                        VCard,
                        {
                          staticClass: "primary",
                          attrs: { rounded: "", "min-height": "270px" }
                        },
                        [
                          _c(
                            VIcon,
                            {
                              staticClass: "white--text my-5",
                              attrs: { size: "100" }
                            },
                            [_vm._v(" mdi-cellphone-message ")]
                          ),
                          _c(
                            VCardText,
                            {
                              staticClass: "title white--text font-weight-black"
                            },
                            [_vm._v(" Envio de mensagens SMS ")]
                          )
                        ],
                        1
                      ),
                      _c(VCardText, { staticClass: "text-left" }, [
                        _vm._v(
                          " Envios automáticos de SMS pré configurados pelo usuário (com pequeno custo adicional por envio) "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "text-center",
                      attrs: { md: "12", lg: "2" }
                    },
                    [
                      _c(
                        VCard,
                        {
                          staticClass: "primary",
                          attrs: { rounded: "", "min-height": "270px" }
                        },
                        [
                          _c(
                            VIcon,
                            {
                              staticClass: "white--text my-5",
                              attrs: { size: "100" }
                            },
                            [_vm._v(" mdi-email ")]
                          ),
                          _c(
                            VCardText,
                            {
                              staticClass: "title white--text font-weight-black"
                            },
                            [_vm._v(" Envio de Emails automáticos ")]
                          )
                        ],
                        1
                      ),
                      _c(VCardText, { staticClass: "text-left" }, [
                        _vm._v(
                          " Envios automáticos de emails pré configurados pelo usuário (com pequeno custo adicional por envio) "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "text-center",
                      attrs: { md: "12", lg: "2", cols: "12" }
                    },
                    [
                      _c(
                        VCard,
                        {
                          staticClass: "primary",
                          attrs: { "min-height": "270px" }
                        },
                        [
                          _c(
                            VIcon,
                            {
                              staticClass: "white--text my-5",
                              attrs: { size: "100" }
                            },
                            [_vm._v(" mdi-chart-arc ")]
                          ),
                          _c(
                            VCardText,
                            {
                              staticClass: "title white--text font-weight-black"
                            },
                            [_vm._v(" Relatórios Detalhados ")]
                          )
                        ],
                        1
                      ),
                      _c(VCardText, { staticClass: "text-left" }, [
                        _vm._v(
                          " Tenha em mãos relatórios completos da aplicação, desde número de uploads, recuperados, em recuperação e muito mais! "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(VCol, {
                    staticClass: "text-center",
                    attrs: { md: "12", lg: "1" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(VCol)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }