import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Home from '@/views/Home.vue';
import Sobre from '@/views/Sobre.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '',
        name: 'home',
        component: Home,
        meta: { authRequired: true }
    },
    {
        path: '/sobre',
        name: 'sobre',
        component: Sobre,
        meta: { authRequired: true }
    },
    {
        path: '/consulta-titulos',
        name: 'consulta-titulos',
        component: () => import('@/views/ConsultaTitulos.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/detalhe-titulo',
        name: 'detalhe-titulo',
        component: () => import('@/views/DetalheTitulo.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/download-instrumentos',
        name: 'download-instrumentos',
        component: () => import('@/views/InstrumentosEmMassa.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/listar-agrupadores',
        name: 'listar-agrupadores',
        component: () => import('@/views/ListarAgrupadores.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/incluir-titulo',
        name: 'incluir-titulo',
        component: () => import('@/views/EditarTitulo.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/upload-titulos',
        name: 'upload-titulos',
        component: () => import('@/views/UploadTitulos.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/detalhe-titulo',
        name: 'DetalheTitulo',
        component: () => import('@/views/DetalheTituloView.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/listar-usuarios',
        name: 'ListarUsuarios',
        component: () => import('@/views/ListarUsuarios.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/detalhe-oferta',
        name: 'DetalheOferta',
        component: () => import('@/views/DetalheOfertaView.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/login-bvs',
        name: 'login Bvs',
        component: () => import('@/views/LoginBvs.vue')
    },
    {
        path: '/comarcas',
        name: 'comarcas',
        component: () => import('@/views/ListarCartorios.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/listar-cartorios',
        name: 'listar-cartorios',
        component: () => import('@/views/ListarCartorios.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/exceldetalhado',
        name: 'ExcelDetalhado',
        component: () => import('@/views/ExcelDetalhado.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/financeiro/repasse',
        name: 'FinanceiroRepasse',
        component: () => import('@/views/FinanceiroRepasse.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/consulta-emolumentos',
        name: 'consulta emolumentos',
        component: () => import('@/views/ConsultaEmolumentos.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/esqueci-senha',
        name: 'esqueci senha',
        component: () => import('@/views/EsqueciSenha.vue')
    },
    {
        path: '/ajustes-mensageria',
        name: 'Ajustes Mensageria',
        component: () => import('@/views/ConfiguracoesMensageria.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/relatorios-mensageria',
        name: 'Relatórios Mensageria',
        component: () => import('@/views/RelatoriosMensageria.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/listar-apresentantes',
        name: 'Listar Apresentantes',
        component: () => import('@/views/ListarApresentantes.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/trocar-apresentante',
        name: 'Mudar Apresentante',
        component: () => import('@/views/TrocarApresentante.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/enviar-sms',
        name: 'Enviar SMS',
        component: () => import('@/views/ListarSms.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/enviar-email',
        name: 'Enviar Email',
        component: () => import('@/views/ListarEmail.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/campanhas-whatsapp',
        name: 'WhatsApp',
        component: () => import('@/views/ListarWhatsapp.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/relatorios/campanhas-whatsapp',
        name: 'Relatórios de Campanhas WhatsApp',
        component: () => import('@/views/RelatorioCampanhasWhatsapp.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/relatorios/campanhas-sms',
        name: 'Relatórios de Campanhas SMS',
        component: () => import('@/views/RelatorioCampanhasSMS.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/listar-comarcas',
        name: 'Listar Comarcas',
        component: () => import('@/views/ListarComarcas.vue'),
        meta: { authRequired: true }
    },    
    {
        path: '/cancelamento-em-massa',
        name: 'Cancelamento em massa',
        component: () => import('@/views/CancelamentoEmMassaV2.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/anuencia-em-massa',
        name: 'Anuência em massa',
        component: () => import('@/views/AnuenciaEmMassaV2.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/desistencia-em-massa',
        name: 'Desistência em massa',
        component: () => import('@/views/DesistenciaEmMassaV2.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/agrupador-em-massa',
        name: 'Agrupador em massa',
        component: () => import('@/views/AgrupadorEmMassaV2.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/upload-em-massa',
        name: 'Upload em massa',
        component: () => import('@/views/UploadEmMassa.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/mapa-de-status',
        name: 'Mapa de Status',
        component: () => import('@/views/MapaStatus.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/fluxo-de-protesto',
        name: 'Fluxo de Protesto',
        component: () => import('@/views/FluxoProtesto.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/manual-do-protesto',
        name: 'Manual do Protesto',
        component: () => import('@/views/ManualProtesto.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/manual-do-usuario',
        name: 'Manual do Usuário',
        component: () => import('@/views/ManualUsuario.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/faq',
        name: 'Dúvidas Frequentes',
        component: () => import('@/views/Faq.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/ofertas-globais',
        name: 'Ofertas Globais',
        component: () => import('@/views/OfertasGlobais.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/relatorios/ofertas-globais',
        name: 'Relatórios de Ofertas Globais',
        component: () => import('@/views/RelatorioOfertasGlobais.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/relatorios/ofertas-titulo',
        name: 'Relatórios de OfertasTítulo',
        component: () => import('@/views/RelatorioOfertasTitulo.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/ofertas-titulo',
        name: 'Ofertas Tïtulo',
        component: () => import('@/views/OfertasTitulo.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/renegociacoes',
        name: 'Listar Renegociações',
        component: () => import('@/views/ListarRenegociacoes.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/manual-de-upload',
        name: 'Manual de Upload',
        component: () => import('@/components/ManualUpload.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/documentacao-api',
        name: 'Documentação de API',
        component: () => import('@/components/ManualApi.vue'),
        meta: { authRequired: true }
    },
    {
        path: '/emolumentos',
        name: 'Emolumentos',
        component: () => import('@/views/Emolumentos.vue'),
        meta: { authRequired: true }
    },
    {
        path: '*',
        component: Home,
        meta: { authRequired: true }
    }
];

const router = new VueRouter({
    routes,
    mode: 'history',
    base: process.env.BASE_URL
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.authRequired)) {
        if (!store.state.isAuthenticated) {
            next({
                path: '/login'
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
