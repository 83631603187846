import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VDialog,
    {
      attrs: {
        "max-width": "450px",
        persistent: "",
        color: "background",
        transition: "dialog-bottom-transition"
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        VCard,
        [
          _c(
            VToolbar,
            { attrs: { flat: "", dark: "", dense: "", color: "primary" } },
            [
              _c(
                VBtn,
                {
                  attrs: { icon: "", dark: "" },
                  on: { click: _vm.fecharModal }
                },
                [_c(VIcon, [_vm._v("mdi-close")])],
                1
              ),
              _c(VToolbarTitle, { staticClass: "pa-0" }, [
                _vm._v(" " + _vm._s(_vm.tituloModal) + " ")
              ])
            ],
            1
          ),
          _c(VDivider),
          _c(
            VCardText,
            { staticClass: "py-5 px-6 text-justify content-style" },
            [_c("span", { domProps: { innerHTML: _vm._s(_vm.textoModal) } })]
          ),
          _c(VDivider),
          _c(
            VCardActions,
            { staticClass: "justify-end" },
            [
              _c(
                VBtn,
                {
                  attrs: { color: "success", "data-cy": "confirmacaoModal" },
                  on: { click: _vm.confirmar }
                },
                [_vm._v(" " + _vm._s(_vm.btnConfirmar) + " ")]
              ),
              !_vm.esconderCancelar
                ? _c(
                    VBtn,
                    {
                      staticClass: "ml-4",
                      attrs: { "data-cy": "fecharConfirmacaoModal" },
                      on: { click: _vm.fecharModal }
                    },
                    [_vm._v(" " + _vm._s(_vm.btnCancelar) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }